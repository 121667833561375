<template>
  <b-card>

    <validation-observer
      ref="instanceObserver"
      tag="form"
    >
      <b-row>
        <b-col cols="12">
          <h3 class="mb-0 w-100 ">
            General
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Instance name"
            label-for="instance-name"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Instance name"
              rules="required"
            >
              <b-form-input
                id="instance-name"
                v-model="instance.name"
                placeholder="Name here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Domain name"
            label-for="domain-name"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Domain name"
              rules="latin|required|minMax:3,10|isLowerCase"
              :disabled="isEditDisabled"
            >
              <b-form-input
                id="domain-name"
                v-model.trim="instance.domain_name"
                placeholder="Name here"
                :disabled="isEditDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Full url path"
            label-for="full-url-path"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Full url path"
              rules="required"
              :disabled="isEditDisabled"
            >
              <b-form-input
                id="full-url-path"
                v-model.trim="instance.full_url_path"
                placeholder="https://"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Payment Provider"
            label-for="payment-provider"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment Provider"
              rules="required"
            >
              <v-select
                v-model="instance.provider"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="providersOptions"
                :clearable="false"
                :placeholder="'Select Provider'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          v-if="instance.provider == 'stripe'"
        >
          <b-form-group
            label="Public Stripe key"
            label-for="public-stripe-key"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Public Stripe key"
              rules="required"
            >
              <b-form-input
                id="public-stripe-key"
                v-model="instance.public_stripe_key"
                placeholder="Key here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          v-if="instance.provider == 'stripe'"
        >
          <b-form-group
            label="Private Stripe key"
            label-for="private-stripe-key"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Private Stripe key"
              rules="required"
            >
              <b-form-input
                id="private-stripe-key"
                v-model="instance.private_stripe_key"
                placeholder="Key here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          v-if="instance.provider == 'cardknox'"
        >
          <b-form-group
            label="Cardknox Api Key"
            label-for="cardknox-api-key"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Cardknox Api Key"
              rules="required"
            >
              <b-form-input
                id="cardknox-api-key"
                v-model="instance.cardknox_api_key"
                placeholder="Key here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          v-if="instance.provider == 'cardknox'"
        >
          <b-form-group
            label="Cardknox IFields Key"
            label-for="cardknox-ifields-key"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Cardknox IFields Key"
              rules="required"
            >
              <b-form-input
                id="cardknox-ifields-key"
                v-model="instance.cardknox_ifields_key"
                placeholder="Key here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Logo"
            label-for="logo"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors, validate }"
              name="Logo"
              rules="required"
            >
              <UploadSingleImage
                v-model="instance.logo"
                :image="instance.logo"
                @changed="handleImg($event, 'logo') || validate($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Favicon*"
            label-for="favicon"
            class="mb-2"
            description="*file 512x512 px in PNG, JPG or SVG format"
          >
            <validation-provider
              #default="{ errors, validate }"
              name="Favicon"
              rules="required"
            >
              <UploadSingleImage
                v-model="instance.favicon"
                :image="instance.favicon"
                @changed="handleImg($event, 'favicon')|| validate($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Kind of Instance"
            class="mb-2"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="instance.type"
              :options="kindOfInstanceOptions"
              name="radio-options"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Timezone"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Timezone"
              rules="required"
            >
              <v-select
                v-model="instance.timezone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="timezonesOptions"
                :clearable="false"
                :reduce="option => option.value"
                :placeholder="'Select Timezone'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Payment methods"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment methods"
              rules="required"
            >
              <b-form-checkbox-group
                v-model="instance.payment_methods"
                name="payment-methods"
              >
                <b-form-checkbox
                  v-for="(item, idx) in paymentMethodsList"
                  :key="idx"
                  name="payment-methods"
                  :value="item"
                >
                  {{ item }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Use Gender"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="instance.gender_settings.use_gender"
              name="modules"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="instance.gender_settings.use_gender"
          cols="4"
        >
          <b-form-group
            label="Default Gender"
          >
            <validation-provider
              #default="{ errors }"
              name="Default Gender"
              rules="required"
            >
              <v-select
                v-model="instance.gender_settings.default_gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                :clearable="false"
                :placeholder="'Select Gender'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="instance.gender_settings.use_gender"
          cols="4"
        />

        <b-col
          v-else
          cols="8"
        />


        <b-col
          cols="4"
        >
          <b-form-group
            label="Use Custom Main Page Url"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="instance.use_custom_main_page_url"
              name="modules"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="instance.use_custom_main_page_url"
          cols="4"
        >
          <b-form-group
            label="Main Page Url"
            label-for="main-page-url-path"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Full url path"
              rules="required"
              :disabled="isEditDisabled"
            >
              <b-form-input
                id="main-page-url-path"
                v-model.trim="instance.main_page_url"
                placeholder="https://"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Use Terms And Conditions"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="instance.use_terms_and_conditions"
              name="modules"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Styling
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Primary Color"
            class="mb-2"
          >
            <compact-picker
              :value="instance.styling.primary"
              :palette="colorPalette"
              class="color-picker"
              @input="handleColor($event, 'primary')"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Secondary Color"
            class="mb-2"
          >
            <compact-picker
              :value="instance.styling.secondary"
              :palette="colorPalette"
              class="color-picker"
              @input="handleColor($event, 'secondary')"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Secondary Background Color"
            class="mb-2"
          >
            <compact-picker
              :value="instance.styling.secondary_bg"
              :palette="colorPalette"
              class="color-picker"
              @input="handleColor($event, 'secondary_bg')"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Primary Button Color"
            class="mb-2"
          >
            <compact-picker
              :value="instance.styling.primary_button"
              :palette="colorPalette"
              class="color-picker"
              @input="handleColor($event, 'primary_button')"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Secondary Button Color"
            class="mb-2"
          >
            <compact-picker
              :value="instance.styling.secondary_button"
              :palette="colorPalette"
              class="color-picker"
              @input="handleColor($event, 'secondary_button')"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Hero
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Background image"
            label-for="bg-img"
            class="mb-2"
            description="*file no more than 2000 px in width and no more than 600 kb in size"
          >
            <validation-provider
              #default="{ errors, validate }"
              name="Background image"
              rules="required"
            >
              <UploadSingleImage
                v-model="instance.hero_img"
                :image="instance.hero_img"
                @changed="handleImg($event, 'hero_img') || validate($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Message*"
            class="mb-2"
            description="*the message should be short (preferably one sentence or several short ones)"
          >
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-form-textarea
                id="hero-message"
                v-model="instance.hero.message"
                placeholder="Text here"
                no-resize
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Subtitle*"
            class="mb-2"
            description="*one or two, three sentence"
          >
            <validation-provider
              #default="{ errors }"
              name="Subtitle"
              rules="required"
            >
              <b-form-textarea
                id="hero-subtitle"
                v-model="instance.hero.subtitle"
                placeholder="Text here"
                no-resize
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="CTA button text*"
            class="mb-2"
            description="*one or two words"
          >
            <validation-provider
              #default="{ errors }"
              name="CTA button"
              rules="required"
            >
              <b-form-input
                id="cta-button-text"
                v-model="instance.hero.hero_cta_button_text"
                placeholder="Text here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Footer settings
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="8"
        >
          <b-form-group
            label="Сopyright text"
            label-for="copyright-text"
            class="mb-2"
            description="*the message should be short (preferably one sentence or several short ones)"
          >
            <validation-provider
              #default="{ errors }"
              name="Сopyright"
              rules="required"
            >
              <b-form-input
                id="copyright-text"
                v-model="instance.footer.copyright"
                placeholder="Text here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Footer Logo"
            label-for="bg-img"
            class="mb-2"
            description="*file 512x512 px in PNG, JPG or SVG format"
          >
            <validation-provider
              #default="{ errors, validate }"
              name="Footer Logo"
              rules="required"
            >
              <UploadSingleImage
                v-model="instance.footer_img"
                :image="instance.footer_img"
                @changed="handleImg($event, 'footer_img') || validate($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <h5>Сontact information</h5>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required"
            >
              <b-form-input
                id="phone"
                v-model="instance.footer.phone"
                placeholder="Phone number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="instance.footer.email"
                placeholder="Email here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Address"
            label-for="address"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-input
                id="address"
                v-model="instance.footer.address"
                placeholder="Address here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <h5>Social</h5>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Facebook"
            label-for="facebook"
            class="mb-2"
          >
            <b-form-input
              id="facebook"
              v-model="instance.footer.facebook"
              placeholder="Link here"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Instagram"
            label-for="instagram"
            class="mb-2"
          >
            <b-form-input
              id="instagram"
              v-model="instance.footer.instagram"
              placeholder="Link here"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
        >
          <b-form-group
            label="Youtube"
            label-for="youtube"
            class="mb-2"
          >
            <b-form-input
              id="youtube"
              v-model="instance.footer.youtube"
              placeholder="Link here"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Modules
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(item, index) in modulesList"
          :key="index"
          cols="3"
          class="mb-1"
        >
          <b-form-group
            :label="item.name"
            :label-for="item.name"
            class="mb-2"
          >
            <b-form-checkbox
              :id="item.name"
              v-model="instance.modules"
              name="modules"
              :value="item.name"
              :disabled="!item.editable"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="instance.modules.includes('Reports')">
        <b-col
          cols="12"
          class="mb-1"
        >
          <div>Report Option</div>
        </b-col>
        <b-col
          v-for="report in reportsList"
          :key="report.id"
          cols="3"
          class="mb-1"
        >
          <b-form-checkbox
            v-model="instance.report_ids"
            name="reports"
            :value="report.id"
          >{{ report.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Tasks
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="Show tasks before app accepted"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.show_tasks_before_app_accepted"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            Auth
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="Regular Login For Parents"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_regular_login_for_parents"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="Regular Registration For Parents"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_regular_registration_for_parents"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="OAuth Login For Parents"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_oauth_login_for_parents"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="OAuth Registration For Parents"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_oauth_registration_for_parents"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="Regular Login For Staff"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_regular_login_for_staff"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="Regular Registration For Staff"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_regular_registration_for_staff"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="OAuth Login For Staff"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_oauth_login_for_staff"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
          class="mb-1"
        >
          <b-form-group
            label="OAuth Registration For Staff"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="instance.enable_oauth_registration_for_staff"
              name="tasks"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <h3 class="mt-4 mb-0 w-100 ">
            OAuth Providers
          </h3>
          <hr class="mt-2">
        </b-col>
      </b-row>
      <b-card
        v-for="(provider, providerIndex) in instance.oauth_providers"
        :key="providerIndex"
        class="bg-light"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex w-100 justify-content-between mb-1"
          >
            <b-form-checkbox
              v-model="provider.is_active"
              name="oauth"
              switch
              value="1"
              unchecked-value="0"
            >Active
            </b-form-checkbox>
            <feather-icon
              icon="TrashIcon"
              class="text-primary cursor-pointer"
              size="21"
              @click="removeOAuthProvider(providerIndex)"
            />
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Provider Name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Provider Name"
                :rules="{required: provider.is_active}"
                :vid="`Provider Name${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.name"
                  placeholder="Name"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Client ID"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Client ID"
                :rules="{required: provider.is_active}"
                :vid="`Client ID${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.client_id"
                  placeholder="Client ID here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Client Password"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Client Password"
                :rules="{required: provider.is_active}"
                :vid="`Client Password${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.client_password"
                  placeholder="Client Password here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Server Authorize"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Server Authorize"
                :rules="{required: provider.is_active}"
                :vid="`Server Authorize${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.server_auth"
                  placeholder="Server Authorize here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Server Token"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Server Token"
                :rules="{required: provider.is_active}"
                :vid="`Server Token${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.server_token"
                  placeholder="Server Token here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Server User"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Server User"
                :rules="{required: provider.is_active}"
                :vid="`Server User${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.server_user"
                  placeholder="Server User here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Redirect URL"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Redirect URL"
                :rules="{required: provider.is_active}"
                :vid="`Redirect URL${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.redirect_url"
                  placeholder="Redirect URL here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Response Type"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Response Type"
                :rules="{ required: provider.is_active }"
                :vid="`Response Type${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.response_type"
                  placeholder="Response Type here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Scope"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Scope"
                :rules="{required: provider.is_active}"
                :vid="`Scope${providerIndex}`"
              >
                <b-form-tags
                  v-model="provider.scope"
                  placeholder="Add Scope here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="4"
          >
            <b-form-group
              label="Button Name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Button Name"
                :rules="{required: provider.is_active}"
                :vid="`Button Name${providerIndex}`"
              >
                <b-form-input
                  v-model="provider.btn_name"
                  placeholder="Button Name here"
                  :disabled="!provider.is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group
              label="Button Color"
              class="mb-2"
            >
              <compact-picker
                :value="provider.btn_color"
                :palette="colorPalette"
                class="color-picker"
                @input="handleProviderBtnColor($event, providerIndex)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="addOAuthProvider"
          >
            <feather-icon
              icon="PlusIcon"
              size="12"
            />
            Add Provider
          </b-button>
        </b-col>
      </b-row>


      <b-row class="pt-4">
        <b-col cols="12">
          <b-button
            variant="primary"
            class="mr-1"
            @click="handleForm"
          >
            Save changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :to="{ name: 'admin-instances-list' }"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<style scoped lang="scss">

</style>
<script>
import {
  BButton,
  BCard, BCol,
  BFormCheckbox, BFormCheckboxGroup, BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTags,
  BFormTextarea,
  BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Compact } from 'vue-color'
import UploadSingleImage from '@/views/components/upload-images/UploadSingleImage.vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { isLowerCase, latin, minMax, required } from '@core/utils/validations/validations'
import timezones from '@/helpers/timezones'

const providerDefaults = {
  is_active: 1,
  name: '',
  client_id: '',
  client_password: '',
  server_auth: '',
  server_token: '',
  server_user: '',
  redirect_url: '',
  response_type: '',
  scope: [],
  btn_color: '#7367F0',
  btn_name: '',
}

export default {
  name: 'InstanceTabMain',
  components: {
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormTags,
    BFormTextarea,
    BRow,
    BFormCheckbox,
    BCard,
    BButton,
    BFormRadioGroup,
    BFormInput,

    vSelect,
    UploadSingleImage,

    ValidationObserver,
    ValidationProvider,

    'compact-picker': Compact,
  },
  computed: {
    ...mapGetters({
      instance: 'app-instances/getInstance',
      isLoading: 'app-instances/getIsLoading',
      paymentMethodsList: 'app-instances/getPaymentMethodsList',
      reportsList: 'app-instances/getReportsList',
    }),
    isEditDisabled() {
      return this.$router.currentRoute.name === 'admin-edit-instance'
    },
  },
  data() {
    return {
      kindOfInstanceOptions: [
        { text: 'School', value: 1 },
        { text: 'Camp', value: 0 },
      ],
      modulesList: [
        {
          name: 'User Transactions Outside Applications',
          editable: true,
        },
        {
          name: 'Family',
          editable: true,
        },
        {
          name: 'Kanban View',
          editable: false,
        },
        {
          name: 'Payment Plans',
          editable: false,
        },
        {
          name: 'Payment Contracts',
          editable: false,
        },
        {
          name: 'Cashbank',
          editable: false,
        },
        {
          name: 'Wallets',
          editable: false,
        },
        {
          name: 'Custom Fees',
          editable: false,
        },
        {
          name: 'Reports',
          editable: false,
        },
      ],
      colorPalette: [
        '#2BA7E0', '#7367F0', '#8618F3', '#C73CDE', '#CC63D6', '#D85499', '#FC8BA8',
        '#C52953', '#D84C3D', '#EB9246', '#EECF51', '#9EC92A', '#80CF7A', '#65C2AC',
      ],
      required,
      latin,
      minMax,
      isLowerCase,
      timezonesOptions: timezones,
      providersOptions: ['stripe', 'cardknox'],
      genderOptions: ['Male', 'Female'],
    }
  },
  methods: {
    handleImg(img, field) {
      this.instance[field] = img
    },
    handleColor(color, field) {
      this.instance.styling[field] = color.hex
    },
    handleProviderBtnColor(color, index) {
      this.instance.oauth_providers[index].btn_color = color.hex
    },
    addOAuthProvider() {
      this.instance.oauth_providers.push(JSON.parse(JSON.stringify(providerDefaults)))
    },
    removeOAuthProvider(index) {
      this.instance.oauth_providers.splice(index, 1)
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.instanceObserver.validate().then(success => {
          if (success) {
            resolve(true)
            this.$emit('nextTab')
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>